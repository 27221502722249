import { Box, Container, Heading, Text } from 'atomic'
import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import Illustration, { IllustrationType } from 'atomic/components/atoms/illustrations/Illustration'
import * as css from './HelpAboveTheFold.css'
import { ThemeColors } from 'atomic/styles/theme.css'

type PropTypes = {
  illustration: IllustrationType
  backgroundColor: keyof ThemeColors
  title: string
  intro: string
  richTextIntro: string
  useRichTextEditor?: boolean
} & BuilderProps

const HelpAboveTheFold: FC<PropTypes> = ({ illustration, title, intro, richTextIntro, useRichTextEditor, backgroundColor, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Container display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} {...cleanProps}>
      <Box
        position={'relative'}
        alignItems={'center'}
        gap={{ mobile: 'm', desktop: 'xxl' }}
        className={css.container}
        backgroundColor={backgroundColor}
        paddingTop={'xxxl'}
        paddingBottom={'xxl'}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Illustration name={illustration ?? 'heart-hug'} width={300} />
        </Box>
        <Box display={'flex'} flexDirection={'column'} alignItems={{ mobile: 'center', desktop: 'flex-start' }} gap={'s'}>
          <Heading as={'h2'} fontSize={'xxl'} marginBottom={'none'} textAlign={{ mobile: 'center', desktop: 'left' }}>
            {title}
          </Heading>
          {!useRichTextEditor ? (
            <Text fontSize={'base'} fontWeight={'light'} textAlign={{ mobile: 'center', desktop: 'left' }}>
              {intro}
            </Text>
          ) : (
            <Box
              fontSize={'base'}
              fontWeight={'light'}
              textAlign={{ mobile: 'center', desktop: 'left' }}
              dangerouslySetInnerHTML={{ __html: richTextIntro }}
            />
          )}
        </Box>
      </Box>
    </Container>
  )
}

export default HelpAboveTheFold
