import styled, { createGlobalStyle } from 'styled-components'

interface PropTypes {
  show?: boolean
}

const DisableGlobalScroll = createGlobalStyle<PropTypes>`
  html {
    overflow: ${(props) => props.show && 'hidden'};
  }
`

const Overlay = styled.div`
  z-index: 6;
  position: fixed;
  overflow: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(70, 80, 90, 0.73);
  &:focus {
    outline: none;
  }
`
export { DisableGlobalScroll, Overlay }
