import { ThemeColors } from 'atomic/styles/theme.css'
import { FC } from 'react'
import { WavyBackground } from './WavyBackground'

export const specialIllustrationTypes = ['wavy-background'] as const

export type SpecialIllustrationType = (typeof specialIllustrationTypes)[number]

export type SpecialIllustrationProps = {
  name: SpecialIllustrationType
  color: keyof ThemeColors
  width?: number
  height?: number
  className?: string
}

const SpecialIllustration: FC<SpecialIllustrationProps> = ({ name, color, className, width, height }) => {
  switch (name) {
    case 'wavy-background':
      return <WavyBackground className={className} color={color} width={width} height={height} />
  }
}

export default SpecialIllustration
