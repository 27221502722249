import { Box, Container, Heading, Text } from 'atomic'
import { FC } from 'react'
import * as css from './TextComponent.css'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { HeadingProps } from 'atomic/components/atoms/heading/Heading'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import SmoothScrollToId from 'utils/smooth-scroll-to-id'
import { useMediaQuery } from 'react-responsive'
import { ouTheme } from 'atomic/styles/theme.css'

type TextComponentProps = {
  modelType?: 'pages' | 'blog'
  dualColumns?: boolean
  headline?: string
  headlineVariant?: 'heading' | 'subheading'
  headlinePosition?: 'left' | 'center' | 'right'
  isPreamble?: boolean
  paragraphContent: string
  bodyTextAlign?: 'left' | 'center' | 'right'
  anchorRef?: boolean
  anchorRefName?: string
  anchorLink?: boolean
  anchorLinkName?: string
} & BoxProps &
  BuilderProps

const TextComponent: FC<TextComponentProps> = ({
  dualColumns,
  headline,
  headlineVariant,
  headlinePosition,
  isPreamble,
  paragraphContent,
  bodyTextAlign,
  anchorRefName,
  anchorRef,
  anchorLink,
  anchorLinkName,
  modelType = 'blog',
  ...props
}) => {
  let blogHeadlineFlexPosition
  switch (headlinePosition) {
    case 'left':
      blogHeadlineFlexPosition = 'flex-start'
      break
    case 'center':
      blogHeadlineFlexPosition = 'center'
      break
    case 'right':
      blogHeadlineFlexPosition = 'flex-end'
      break
  }

  if (!paragraphContent && !headline) {
    return (
      <Container marginBottom={props.marginBottom ?? 'xxl'}>
        <Text>Add paragraphs in the Block options panel</Text>
      </Container>
    )
  }

  const headlineSettings: Pick<BoxProps, 'marginBottom' | 'fontSize'> & Pick<HeadingProps, 'as'> = {
    marginBottom: headlineVariant === 'heading' ? 'xs' : 'xxs',
    fontSize: headlineVariant === 'heading' ? 'l' : 'base',
    as: headlineVariant === 'heading' ? 'h4' : 'h5',
  }

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  const isDesktopMobile = useMediaQuery({ maxWidth: ouTheme.breakpoints.tablet })

  return (
    <>
      {!headlinePosition ? (
        <Container
          marginBottom={modelType === 'pages' ? 'none' : props.marginBottom ?? 'xl'}
          paddingLeft={'none'}
          paddingRight={'none'}
          className={`${css.textContainer[modelType]}`}
          {...cleanProps}
        >
          <Box style={{ width: '100%' }} id={anchorRef ? anchorRefName : null} marginBottom={'none'}>
            {anchorLink && headline ? (
              <Box
                style={{ textAlign: headlinePosition ?? 'left' }}
                onClick={() => SmoothScrollToId(anchorLinkName, { scrollMarginTop: '100px', scrollBehavior: 'smooth', scrollBlock: 'start' })}
              >
                <Heading {...headlineSettings} textAlign={headlinePosition ?? 'left'} style={{ cursor: 'pointer' }}>
                  {headline}
                </Heading>
              </Box>
            ) : headline && !anchorLink ? (
              <Heading {...headlineSettings} textAlign={headlinePosition ?? 'left'}>
                {headline}
              </Heading>
            ) : null}
            <Box
              style={{ width: '100%' }}
              lineHeight={'xl'}
              textAlign={bodyTextAlign ? bodyTextAlign : 'left'}
              fontSize={isPreamble ? 'l' : modelType === 'pages' ? 's' : 'base'}
              fontWeight={modelType === 'blog' ? 'light' : 'normal'}
              marginBottom={'none'}
              className={dualColumns && !isDesktopMobile ? css.columns : null}
              dangerouslySetInnerHTML={{ __html: paragraphContent }}
            />
          </Box>
        </Container>
      ) : (
        <Container
          marginBottom={modelType === 'pages' ? 'none' : props.marginBottom ?? 'xl'}
          paddingLeft={'none'}
          paddingRight={'none'}
          className={`${css.textContainer[modelType]}`}
          justifyContent={blogHeadlineFlexPosition}
          {...cleanProps}
        >
          <Box style={{ width: '100%' }} id={anchorRef ? anchorRefName : null} marginBottom={'none'}>
            {anchorLink && headline ? (
              <Box
                style={{ textAlign: headlinePosition ?? 'left' }}
                onClick={() => SmoothScrollToId(anchorLinkName, { scrollMarginTop: '100px', scrollBehavior: 'smooth', scrollBlock: 'start' })}
              >
                <Heading {...headlineSettings} textAlign={headlinePosition ?? 'left'} style={{ cursor: 'pointer' }}>
                  {headline}
                </Heading>
              </Box>
            ) : headline && !anchorLink ? (
              <Heading {...headlineSettings} textAlign={headlinePosition ?? 'left'}>
                {headline}
              </Heading>
            ) : null}
            <Box
              style={{ width: '100%' }}
              lineHeight={'xl'}
              textAlign={bodyTextAlign ? bodyTextAlign : 'left'}
              fontSize={isPreamble ? 'l' : modelType === 'pages' ? 's' : 'base'}
              fontWeight={modelType === 'blog' ? 'light' : 'normal'}
              className={dualColumns ? css.columns : null}
              marginBottom={'none'}
              dangerouslySetInnerHTML={{ __html: paragraphContent }}
            />
          </Box>
        </Container>
      )}
    </>
  )
}

export default TextComponent
