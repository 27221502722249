import { Container } from 'atomic'
import { FC } from 'react'

import { container, illustration, illustrationBackdrop } from './SellingPointsSection.css'
import InfoCard from '../infocard/InfoCard'
import { KluddIllustrationType, IllustrationType } from 'atomic/components/atoms/illustrations'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { ThemeColors } from 'atomic/styles/theme.css'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import KluddIllustration from 'atomic/components/atoms/illustrations/KluddIllustration'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'

type SellingPoint = {
  color: keyof ThemeColors
  title: string
  body: string
  kluddType?: KluddIllustrationType
  kluddColor?: keyof ThemeColors
  kluddWidth?: number
  illustration?: IllustrationType
}

type SellingPointsSectionProps = BoxProps &
  BuilderProps & {
    usp1?: SellingPoint
    usp2?: SellingPoint
    usp3?: SellingPoint
    usp4?: SellingPoint
  }

const SellingPointsSection: FC<SellingPointsSectionProps> = ({ usp1, usp2, usp3, usp4, ...props }) => {
  const { cmsOrLokalise } = useCmsOrTranslation()

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Container className={container} {...cleanProps}>
      <InfoCard
        title={cmsOrLokalise(usp1?.title, 'productpage_factbox_sustainability_header')}
        text={cmsOrLokalise(usp1?.body, 'productpage_factbox_sustainability_body')}
        illustration={
          <>
            <KluddIllustration
              name={usp1?.kluddType ?? 'kludd-13'}
              color={usp1?.kluddColor ?? 'mossa2'}
              width={usp1?.kluddWidth ?? 130}
              className={illustrationBackdrop}
            />
            <Illustration className={illustration} name={usp1?.illustration ?? 'flower'} />
          </>
        }
        color={usp1?.color ?? 'lav4'}
      />
      <InfoCard
        title={cmsOrLokalise(usp2?.title, 'productpage_factbox_printing_header')}
        text={cmsOrLokalise(usp2?.body, 'productpage_factbox_printing_body')}
        illustration={
          <>
            <KluddIllustration
              name={usp2?.kluddType ?? 'kludd-13'}
              color={usp2?.kluddColor ?? 'kantarell1'}
              width={usp2?.kluddWidth ?? 130}
              className={illustrationBackdrop}
            />
            <Illustration className={illustration} name={usp2?.illustration ?? 'delivery'} />
          </>
        }
        color={usp2?.color ?? 'lav4'}
      />
      <InfoCard
        title={cmsOrLokalise(usp3?.title, 'productpage_factbox_collab_header')}
        text={cmsOrLokalise(usp3?.body, 'productpage_factbox_collab_body')}
        illustration={
          <>
            <KluddIllustration
              name={usp3?.kluddType ?? 'kludd-13'}
              color={usp3?.kluddColor ?? 'ljung2'}
              width={usp3?.kluddWidth ?? 130}
              className={illustrationBackdrop}
            />
            <Illustration className={illustration} name={usp3?.illustration ?? 'hearts'} />
          </>
        }
        color={usp3?.color ?? 'lav4'}
      />
      <InfoCard
        title={cmsOrLokalise(usp4?.title, 'productpage_factbox_seamless_header')}
        text={cmsOrLokalise(usp4?.body, 'productpage_factbox_seamless_body')}
        illustration={
          <>
            <KluddIllustration
              name={usp4?.kluddType ?? 'kludd-13'}
              color={usp4?.kluddColor ?? 'blåbär2'}
              width={usp4?.kluddWidth ?? 130}
              className={illustrationBackdrop}
            />
            <Illustration className={illustration} name={usp4?.illustration ?? 'book'} />
          </>
        }
        color={usp4?.color ?? 'lav4'}
      />
    </Container>
  )
}

export default SellingPointsSection
