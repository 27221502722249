import { Box, Button, Carousel, Container, Heading, Text } from 'atomic'
import { FC } from 'react'
import TrustpilotWidget from 'components/trustpilot/Trustpilot'
import { carousel, container } from './HeroProduct.css'
import Image from 'next/image'

import InfoBox from './info_box/InfoBox'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { ThemeColors, ThemeFontSizes, ouTheme } from 'atomic/styles/theme.css'
import { isAndroid, isIOS } from 'react-device-detect'
import useAppLinks from 'hooks/useAppLinks'
import { trackOutboundLink } from 'lib/analytics-utils'
import { Trans } from 'next-i18next'
import { useBackend } from 'context/BackendContext'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { LocaleKey } from 'types/LokalizeKeys'
import DiscountCircle from './discount_circle/DiscountCircle'
import ExternalLink from 'components/externallink/ExternalLink'
import { useRouter } from 'next/router'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import { IllustrationType } from 'atomic/components/atoms/illustrations'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'

type Slide = {
  id?: number
  src: string
}
type HeroSlide = string | Slide
type ProductType = 'photo' | 'print' | 'wedding' | 'travel' | 'baby' | 'yearbook' | 'art'

type DiscountContent = {
  showDiscountCircle: boolean
  title?: string
  titleSize?: keyof ThemeFontSizes
  description?: LocaleKey
  illustration?: IllustrationType
  illustrationWidth?: number
  illustrationHeight?: number
  color?: keyof ThemeColors
  link?: string
}

type HeroProductProps = {
  productType: ProductType
  title?: string
  subtitle?: string
  heroSlides?: HeroSlide[]
  heroImage?: string
  startCreateButtonText?: string
  hintContent?: DiscountContent
  infoList?: keyof ThemeColors
} & BoxProps &
  BuilderProps

const HeroProduct: FC<HeroProductProps> = ({
  productType,
  hintContent,
  title,
  subtitle,
  heroImage,
  heroSlides,
  startCreateButtonText,
  infoList,
  ...props
}) => {
  const { cmsOrLokalise } = useCmsOrTranslation()
  const { storeData } = useBackend()

  const slides = heroSlides?.map((slide) => (typeof slide === 'string' ? slide : slide.src))
  const printsBase = storeData?.find((product) => product.key === 'card-13x18cm')
  const booksBase = storeData?.find((product) => product.key === 'book-20x20cm-soft-cover')

  const printPrice = `${printsBase?.price} ${printsBase?.currency}`
  const bookPrice = `${booksBase?.price} ${booksBase?.currency}`

  const defaultCreateButtonKey: LocaleKey = 'productpage_photobooks_create_button'

  const defaultTitleKey = productType === 'print' ? 'productpage_prints_header' : 'productpage_photobooks_header'

  const heroTitle = cmsOrLokalise(title, defaultTitleKey)

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  // TODO: Remove this when hintContent is implemented correct as a symbol in Builder
  // Special hack to show hint content for all product pages

  // determine if the current date is within the date range for the prints at home campaign
  const today = new Date()
  const fromDate = new Date('2024-09-01')
  const toDate = new Date('2024-09-08')
  const isWithinDateRange = today.getTime() >= fromDate.getTime() && today.getTime() <= toDate.getTime()

  hintContent.showDiscountCircle = isWithinDateRange ? true : false
  hintContent.color = 'hjortron3'
  hintContent.description = 'bannerboll_summer_25'
  hintContent.illustration = 'discount-25'
  hintContent.illustrationWidth = 80

  return (
    <Box position="relative" {...cleanProps}>
      <Box className={carousel}>
        {slides && (
          <Carousel
            items={slides.map((src, index) => {
              return (
                <Image
                  key={src}
                  alt={`carousel_image_${index}`}
                  draggable={false}
                  priority
                  fill
                  sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                  quality={65}
                  style={{ objectFit: 'cover' }}
                  src={src}
                />
              )
            })}
          />
        )}
        {heroImage && (
          <Image
            fill
            style={{ objectFit: 'cover' }}
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            alt="Hero image"
            src={heroImage}
          />
        )}

        {hintContent && hintContent?.showDiscountCircle && (
          <DiscountCircle
            background={hintContent?.color ?? 'ljung1'}
            illustration={
              <Illustration
                width={hintContent?.illustrationWidth ?? 65}
                height={hintContent?.illustrationHeight ?? undefined}
                name={hintContent.illustration}
              />
            }
            link={hintContent?.link}
          >
            {hintContent.title && (
              <Text fontSize={hintContent?.titleSize ?? 'l'} marginBottom="xxxs" fontWeight="bold">
                {hintContent.title}
              </Text>
            )}
            <Text fontWeight="normal" marginBottom="xs">
              <Trans key={hintContent.description} i18nKey={hintContent.description} />
            </Text>
          </DiscountCircle>
        )}
      </Box>

      <Container className={container}>
        <div></div>
        <Box paddingLeft={{ mobile: 'none', desktop: 'xxl' }} paddingTop={{ mobile: 'l', desktop: 'xxxl' }}>
          <Heading marginBottom="xs" as="h1">
            {heroTitle}
          </Heading>
          <Text fontSize="l" as="p" marginBottom="s">
            {productType !== 'print' ? (
              <Trans
                key={'productpage_photobooks_subheading'}
                i18nKey={'productpage_photobooks_subheading'}
                values={{ price: bookPrice }}
                components={{
                  price: (
                    <Text as="span" fontWeight="semibold">
                      price
                    </Text>
                  ),
                }}
              />
            ) : (
              <Trans
                key={'productpage_prints_subheading'}
                i18nKey={'productpage_prints_subheading'}
                values={{ price: printPrice }}
                components={{
                  price: (
                    <Text as="span" fontWeight="semibold">
                      price
                    </Text>
                  ),
                }}
              />
            )}
          </Text>

          <Text fontSize="s" as="p" marginBottom="m">
            {cmsOrLokalise(subtitle, productType !== 'print' ? 'productpage_photobooks_body' : 'productpage_prints_body')}
          </Text>

          <StartCreatingButton
            productType={productType === 'print' ? 'print' : 'photo'}
            buttonText={startCreateButtonText}
            defaultLocalKey={defaultCreateButtonKey}
          />

          <TrustpilotWidget variant="micro" marginBottom="xl" />

          <InfoBox
            background={infoList ?? 'mossa4'}
            keys={
              productType === 'print'
                ? Array.from({ length: 4 }).map((_, i) => `productpage_prints_factbox_line${i + 1}`)
                : Array.from({ length: 4 }).map((_, i) => `productpage_photobooks_factbox_line${i + 1}`)
            }
          />
        </Box>
      </Container>
    </Box>
  )
}

type StartCreatingButtonProps = {
  productType: Extract<ProductType, 'photo' | 'print'>
  buttonText: string
  defaultLocalKey: LocaleKey
}
const StartCreatingButton: FC<StartCreatingButtonProps> = ({ productType, buttonText, defaultLocalKey }): JSX.Element => {
  const router = useRouter()
  const { appStoreUrl, googlePlayUrl } = useAppLinks()
  const { cmsOrLokalise } = useCmsOrTranslation()
  const btnText = cmsOrLokalise(buttonText, defaultLocalKey)
  if (isAndroid || isIOS) {
    const url = isAndroid ? googlePlayUrl : appStoreUrl
    return (
      <a href={url} onClick={() => trackOutboundLink(url)}>
        <Button marginBottom="xl">{btnText}</Button>
      </a>
    )
  }

  return (
    <ExternalLink href={`https://app.onceupon.photo/authentication/signinAnonymously/?productType=${productType}&lang=${router.locale}`}>
      <Button marginBottom="xl">{btnText}</Button>
    </ExternalLink>
  )
}

export default HeroProduct
