import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { Box, Container, Heading, Text } from 'atomic'
import Image from 'next/image'
import { ThemeColors, ThemeFontWeights, ouTheme, themeVars } from 'atomic/styles/theme.css'
import * as css from './PageHero.css'
import Button, { ButtonType } from 'atomic/components/atoms/button/Button'
import { Link } from 'components/link/Link'

type HeroButton = {
  text: string
  type: ButtonType
  link: string
  size: 'l' | 'm' | 's'
}

type HeroQuote = {
  text: string
  signature: string
  color: keyof ThemeColors
}

type PageHeroProps = {
  title: string
  uppercase?: boolean
  useRichTextEditor?: boolean
  richTextTitle: string
  fontWeight: keyof ThemeFontWeights
  intro: string
  useButton?: boolean
  button?: HeroButton
  image: string
  imagePosition: 'center' | 'top' | 'bottom' | 'left' | 'right'
  fullbleedLayout?: boolean
  titleSize?: keyof typeof themeVars.fontSizes
  introSize?: keyof typeof themeVars.fontSizes
  quoteInImage: boolean
  quote?: HeroQuote
} & BuilderProps

const PageHero: FC<PageHeroProps> = ({
  title,
  uppercase = false,
  fontWeight = 'bold',
  useRichTextEditor = false,
  richTextTitle,
  intro,
  useButton = false,
  button,
  image,
  imagePosition = 'center',
  fullbleedLayout,
  titleSize,
  introSize,
  quoteInImage = false,
  quote,
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <>
      {fullbleedLayout ? (
        <Box
          position={'relative'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{
            backgroundImage: `url(${image})`,
            backgroundPosition: imagePosition,
          }}
          className={css.fullBleed}
          {...cleanProps}
        >
          <Box position={'absolute'} className={css.gradientOverlay} />
          <Container className={css.fullBleedContainer} position={'relative'} padding={{ mobile: 'l', desktop: 'xxl' }}>
            {/* Empty column to match layout */}
            <div />
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'xxl'}>
              <Box display={'flex'} flexDirection={'column'} gap={'m'} alignItems={'center'} justifyContent={'center'}>
                {!useRichTextEditor ? (
                  <Heading
                    as={'h2'}
                    fontSize={titleSize ?? 'xxl'}
                    margin={'none'}
                    textAlign={'center'}
                    color={'white'}
                    fontWeight={fontWeight ?? 'bold'}
                    style={{ textTransform: uppercase ? 'uppercase' : 'initial' }}
                  >
                    {title}
                  </Heading>
                ) : (
                  <Box
                    fontSize={titleSize ?? 'xxl'}
                    margin={'none'}
                    textAlign={'center'}
                    color={'white'}
                    fontWeight={fontWeight ?? 'bold'}
                    style={{ textTransform: uppercase ? 'uppercase' : 'initial', lineHeight: '1' }}
                    className={css.richTextTitle}
                    dangerouslySetInnerHTML={{ __html: richTextTitle }}
                  />
                )}
                <Text fontWeight={'normal'} textAlign={'center'} margin={'none'} fontSize={introSize ?? 'l'} color={'white'}>
                  {intro}
                </Text>
                {button && useButton && (
                  <Link href={button?.link} style={{ outline: 'none' }}>
                    <Button variant={button?.type} size={button?.size} style={{ outline: 'none' }}>
                      {button?.text}
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
            {/* Empty column to match layout */}
            <div />
          </Container>
        </Box>
      ) : (
        <Box position={'relative'} {...cleanProps}>
          <Box
            className={css.imageContainer}
            position={{ mobile: 'relative', desktop: 'absolute' }}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            background="mossa2"
          >
            <Image
              fill
              src={image}
              sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
              style={{ objectFit: 'cover', objectPosition: `${imagePosition}` }}
              alt={`${title} image`}
              priority
            />
            {quoteInImage && (
              <Box
                style={{ maxWidth: 685 }}
                position={'absolute'}
                display={'flex'}
                flexDirection={'column'}
                gap={{ mobile: 's', desktop: 'l' }}
                marginTop={'xxxl'}
                paddingX={{ mobile: 'l', desktop: 'none' }}
              >
                <Heading
                  as="h3"
                  fontSize={{ mobile: 'xxl', desktop: 'xxxl' }}
                  color={quote.color ?? 'white'}
                  textAlign={'center'}
                  marginBottom={'none'}
                >
                  {quote.text}
                </Heading>
                <Text
                  fontSize={{ mobile: 'l', desktop: 'xl' }}
                  fontWeight={'normal'}
                  color={quote.color ?? 'white'}
                  textAlign={'center'}
                  marginBottom={'none'}
                >
                  {quote.signature}
                </Text>
              </Box>
            )}
          </Box>
          <Container className={css.container}>
            {/* Empty column to match layout */}
            <div />
            <Box
              paddingLeft={{ mobile: 'none', desktop: 'xxl' }}
              paddingTop={{ mobile: 'l', desktop: 'xxxl' }}
              paddingBottom={{ mobile: 'l', desktop: 'xxxl' }}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              gap={{ mobile: 'l', desktop: 'xxxl' }}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={'flex-start'} gap={'m'}>
                <Heading
                  as={'h2'}
                  fontSize={titleSize ?? 'xxl'}
                  margin={'none'}
                  textAlign={'left'}
                  fontWeight={fontWeight ?? 'bold'}
                  style={{ textTransform: uppercase ? 'uppercase' : 'initial' }}
                >
                  {title}
                </Heading>
                <Text fontWeight={'light'} fontSize={introSize ?? 'l'} margin={'none'} textAlign={'left'}>
                  {intro}
                </Text>
                {button && useButton && (
                  <Link href={button?.link} style={{ outline: 'none' }}>
                    <Button variant={button?.type} size={button?.size} style={{ outline: 'none' }}>
                      {button?.text}
                    </Button>
                  </Link>
                )}
              </Box>
            </Box>
          </Container>
        </Box>
      )}
    </>
  )
}

export default PageHero
