import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { Container } from 'atomic'
import * as css from './HelpContainer.css'

const HelpContainer: FC<BuilderProps> = (props) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Container
      display={'flex'}
      flexDirection={'column'}
      alignItems={'flex-start'}
      justifyContent={'center'}
      className={css.container}
      {...cleanProps}
    >
      {props.children}
    </Container>
  )
}

export default HelpContainer
