import { FC } from 'react'

const Check: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="10" viewBox="0 0 12 10">
    <path
      fill="none"
      fillRule="evenodd"
      stroke="#46505A"
      strokeLinecap="round"
      strokeWidth="2"
      d="M10.294 1L5.532 8.907c-.037.037-.097.037-.134 0L1 4.508"
    ></path>
  </svg>
)

export default Check
