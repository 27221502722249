import { FC } from 'react'

type PropTypes = {
  dark?: boolean
}
const DownArrow: FC<PropTypes> = ({ dark }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 30 30">
    <path
      fill={dark ? '#222222' : '#ffffff'}
      fillRule="nonzero"
      d="M9.293 14.293a1 1 0 011.414 0L18 21.585l7.293-7.292a1 1 0 011.32-.083l.094.083a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-8-8a1 1 0 010-1.414z"
    ></path>
  </svg>
)

export default DownArrow
