/* eslint-disable @next/next/no-img-element */
import { Box, Container, Heading } from 'atomic'
import { FC } from 'react'

import { BoxProps } from 'atomic/components/atoms/box/Box'
import { cardItem, cards, container, stripe } from './PaymentSection.css'
import InternalLink from 'components/internallink/InternalLink'
import { useLink } from 'context/LinkContext'
import { useRouter } from 'next/router'
import { Trans } from 'next-i18next'
import { useSafeTranslation } from 'hooks/useSafeTranslation'
import { useBackend } from 'context/BackendContext'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

const PaymentSection: FC<BoxProps & BuilderProps> = (props) => {
  const { t } = useSafeTranslation()
  const { getLocalePath } = useLink()
  const { paymentOptions } = useBackend()
  const router = useRouter()

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Box background="mossa4" paddingY="xxl">
      <Container className={container} {...cleanProps}>
        <Box background="white" paddingX="m" paddingY="l" borderRadius="m">
          <Heading as="h3" fontSize="l" marginBottom="l">
            {t('productpage_photobooks_payment_header')}
          </Heading>
          <Box className={cards}>
            {paymentOptions?.map((icon) => (
              // eslint-disable-next-line @next/next/no-img-element
              <img key={icon.name} className={cardItem} width="0" height="0" src={icon.image?.url} alt={icon.name} />
            ))}
          </Box>
          <Box className={cards} marginTop="m">
            <img src="/icons/PoweredByStripe_blurple.svg" className={stripe} alt="Powered by Stripe" />
          </Box>
        </Box>
        <Box background="white" paddingX="m" paddingY="l" borderRadius="m">
          <Heading as="h3" fontSize="l" marginBottom="xs">
            {t('productpage_photobooks_data_header')}
          </Heading>

          <Trans
            i18nKey="productpage_photobooks_data_body"
            components={{
              policy: (
                <InternalLink style={{ fontWeight: 'bold' }} href={getLocalePath('/help/personal-data-policy', router.locale)}>
                  policy
                </InternalLink>
              ),
            }}
          />
        </Box>
      </Container>
    </Box>
  )
}

export default PaymentSection
