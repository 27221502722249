import React, { FC } from 'react'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { ThemeColors, ouTheme } from 'atomic/styles/theme.css'
import { Box, Container } from 'atomic'
import FunFactsItem, { FunFactItem } from './FunFactItem'
import * as css from './FunFacts.css'

type FunFactsProps = {
  backgroundColor: keyof ThemeColors
  funFact1: FunFactItem
  funFact2: FunFactItem
  funFact3: FunFactItem
} & BuilderProps

const FunFacts: FC<FunFactsProps> = ({ backgroundColor, funFact1, funFact2, funFact3, ...props }) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Box display={'flex'} justifyContent={'center'} style={{ width: '100%' }} {...cleanProps}>
      <Box paddingX={'s-l'} style={{ width: '100%', maxWidth: ouTheme.containerWidth }}>
        <Container
          display={{ mobile: 'flex' }}
          flexDirection={{ mobile: 'column' }}
          backgroundColor={backgroundColor}
          gap={'xl'}
          paddingY={'xxxl'}
          paddingX={{ mobile: 's-l', desktop: 'xxxl' }}
          className={css.container}
        >
          <FunFactsItem fact={funFact1} />
          <FunFactsItem fact={funFact2} />
          <FunFactsItem fact={funFact3} />
        </Container>
      </Box>
    </Box>
  )
}

export default FunFacts
