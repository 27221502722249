import { Container, Box, Hint, Text, Heading } from 'atomic'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import React, { FC } from 'react'
import * as css from './TipsSection.css'
import InternalLink from 'components/internallink/InternalLink'
import { useLink } from 'context/LinkContext'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { ThemeColors, ouTheme } from 'atomic/styles/theme.css'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'
import { IllustrationType } from 'atomic/components/atoms/illustrations'
import Illustration from 'atomic/components/atoms/illustrations/Illustration'

type TipsSectionProps = BoxProps &
  BuilderProps & {
    title?: string
    description?: string
    linkHref?: string
    linkText?: string
    circleText?: string
    circleColor?: keyof ThemeColors
    smallImage: string
    coverImage: string
    illustration: IllustrationType
    illustrationBoxColor: keyof ThemeColors
    illustrationBoxText: string
    backgroundColor?: keyof ThemeColors
  }
const TipsSection: FC<TipsSectionProps> = ({
  title,
  description,
  linkHref,
  linkText,
  circleText,
  circleColor = 'blåbär2',
  smallImage = '/images/photobooks/news-landscape.jpg',
  coverImage = '/images/weddingbooks/tips-cover.jpg',
  illustration = 'hearts',
  illustrationBoxColor = 'ljung3',
  illustrationBoxText,
  backgroundColor = 'transparent',
  ...props
}) => {
  const { getLocalePath } = useLink()
  const router = useRouter()

  const { cmsOrLokalise } = useCmsOrTranslation()

  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Box backgroundColor={backgroundColor ?? 'transparent'} paddingY={backgroundColor ? 'xl' : 'none'}>
      <Container {...cleanProps} className={css.container}>
        <Box style={{ gridArea: 'a' }} justifyContent="center" display="flex" flexDirection="column" paddingRight={{ mobile: 'none', tablet: 'xxl' }}>
          <Hint background={circleColor} marginBottom="l" marginTop="s" className={css.hint}>
            <Text as="span" fontFamily="accent" fontSize="xl">
              {cmsOrLokalise(circleText, 'Photobookpage_tips')}
            </Text>
          </Hint>
          <Heading as="h2" fontSize="xl" marginBottom="l">
            {title}
          </Heading>
          <Text>
            {description}{' '}
            <InternalLink href={getLocalePath(linkHref, router.locale)} style={{ fontWeight: 'bold' }}>
              {linkText}
            </InternalLink>
          </Text>
        </Box>

        <Box style={{ gridArea: 'b', position: 'relative' }} background="blåbär0">
          <Image
            fill
            loading="lazy"
            style={{ objectFit: 'cover' }}
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            src={smallImage}
            alt=""
          />
        </Box>
        <Box
          style={{ gridArea: 'c', position: 'relative', width: '100%' }}
          flexDirection="column"
          justifyContent="center"
          display="flex"
          alignItems="center"
          gap="m"
          padding="xl"
          background={illustrationBoxColor}
        >
          <Illustration className={css.illustration} name={illustration} />
          <Text fontFamily="accent" fontSize="xl" textAlign="center" className={css.illustrationText}>
            {illustrationBoxText}
          </Text>
        </Box>
        <Box style={{ gridArea: 'd', position: 'relative' }}>
          <Image
            fill
            loading="lazy"
            style={{ objectFit: 'cover' }}
            sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
            src={coverImage}
            alt=""
          />
        </Box>
      </Container>
    </Box>
  )
}

export default TipsSection
