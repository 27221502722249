import { FC } from 'react'

import { trackOutboundLink } from '../../lib/analytics-utils'
import FacebookIcon from '../icons/FacebookIcon'
import InstagramIcon from '../icons/InstagramIcon'
import PinterestIcon from '../icons/Pinterest'

import styles from './Socials.module.scss'

type PropTypes = {
  className?: string
}

const facebookUrl = 'https://www.facebook.com/onceuponpublishing'
const instagramUrl = 'https://www.instagram.com/onceuponapp/'
const pinterestUrl = 'https://www.pinterest.se/onceuponpublishing/'

const Social: FC<PropTypes> = ({ className }) => {
  return (
    <div className={`${styles.container} ${className}`}>
      <a onClick={() => trackOutboundLink(instagramUrl)} href={instagramUrl} target="_blank" rel="noreferrer" aria-label="Link to Instagram">
        <InstagramIcon />
      </a>
      <a onClick={() => trackOutboundLink(pinterestUrl)} href={pinterestUrl} target="_blank" rel="noreferrer" aria-label="Link to Pinterest">
        <PinterestIcon />
      </a>
      <a onClick={() => trackOutboundLink(facebookUrl)} href={facebookUrl} target="_blank" rel="noreferrer" aria-label="Link to Facebook">
        <FacebookIcon />
      </a>
    </div>
  )
}

export default Social
