import { ouTheme, themeVars } from 'atomic/styles/theme.css'
import React, { FC } from 'react'
import * as css from './HeroBlog.css'
import Image from 'next/image'
import { Box, Container, Heading, Text } from 'atomic'
import StoriesUnderline from '../../icons/StoriesUnderline'
import { useRouter } from 'next/router'
import useBlogArticleInfo from 'hooks/useBlogArticleInfo'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'
import { useMediaQuery } from 'react-responsive'

type VideoSettingsProps = {
  videosrc: string
  loop?: boolean
  muted?: boolean
  fileType: 'mp4' | 'webm' | 'mov'
}

type GuestAuthorSettingsProps = {
  name: string
  email: string
  photo: string
}

type HeroBlogProps = {
  fullBleedLayout?: boolean
  title: string
  intro: string
  image: string
  imagePosition?: string
  videoCheck?: boolean
  videoSettings?: VideoSettingsProps
  guestAuthor?: boolean
  guestAuthorSettings?: GuestAuthorSettingsProps
} & BuilderProps

const HeroBlog: FC<HeroBlogProps> = ({ fullBleedLayout, imagePosition, videoCheck, videoSettings, guestAuthor, guestAuthorSettings, ...props }) => {
  const router = useRouter()
  const storiesArticleInfo = useBlogArticleInfo()
  // if article has an scheduled date show that, otherwise show the first published date
  const date = new Date(storiesArticleInfo.startDate ?? storiesArticleInfo.createdDate)
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  }

  const formattedDate = date.toLocaleDateString(router.locale, options)
  const author = guestAuthor ? guestAuthorSettings : storiesArticleInfo.blogData?.author
  const readingTime = storiesArticleInfo.blogData?.readingTime

  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  const isDesktopMobile = useMediaQuery({ maxWidth: ouTheme.breakpoints.tablet })

  return (
    <>
      {fullBleedLayout ? (
        <Box
          position={'relative'}
          display={'flex'}
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          style={{
            backgroundImage: !videoCheck ? `url(${props.image})` : 'none',
            backgroundPosition: !videoCheck ? imagePosition : 'none',
            height: '100%',
            maxHeight: !isDesktopMobile ? 956 : 749,
          }}
          className={css.fullBleed}
          {...cleanProps}
        >
          {videoCheck && (
            // eslint-disable-next-line jsx-a11y/media-has-caption
            <video
              className={css.fullBleedVideo}
              poster={props.image}
              muted={videoSettings.muted}
              playsInline
              autoPlay
              loop={videoSettings.loop}
              preload={'metadata'}
            >
              <source src={videoSettings.videosrc} type={`video/${videoSettings.fileType ?? 'mp4'}`} />
            </video>
          )}
          <Box position={'absolute'} className={css.gradientOverlay} />
          <Container className={css.fullBleedContainer} position={'relative'} padding={{ mobile: 'l', desktop: 'xxxl' }}>
            <Box />
            <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} gap={'xxl'}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Heading as={'h1'} fontWeight={'normal'} margin={'none'} fontFamily={'accent'} fontSize={'xxl'} color={'white'} lineHeight={'xs'}>
                  Stories
                </Heading>
                <Box display={{ mobile: 'none', desktop: 'flex' }} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                  <StoriesUnderline color={themeVars.colors.white} />
                </Box>
                <Box display={{ mobile: 'flex', desktop: 'none' }} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                  <StoriesUnderline width={'48'} height={'9'} color={themeVars.colors.white} />
                </Box>
              </Box>
              <Box display={'flex'} flexDirection={'column'} gap={'m'}>
                <Heading as={'h2'} margin={'none'} textAlign={'center'} color={'white'}>
                  {props.title}
                </Heading>
                <Text fontWeight={'normal'} textAlign={'center'} margin={'none'} fontSize={'l'} color={'white'}>
                  {props.intro}
                </Text>
              </Box>
            </Box>
            <Box />
          </Container>
        </Box>
      ) : (
        <Box position={'relative'} {...cleanProps}>
          <Box className={css.imageContainer} position={{ mobile: 'relative', desktop: 'absolute' }} background={'mossa2'}>
            {!videoCheck ? (
              <Image
                fill
                src={props.image}
                sizes={`(max-width: ${ouTheme.breakpoints.desktop}) 100vw, 50vw`}
                style={{ objectFit: 'cover', objectPosition: `${imagePosition}` }}
                alt={`${props.title} image`}
                priority
              />
            ) : (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video
                className={css.video}
                poster={props.image}
                muted={videoSettings.muted}
                playsInline
                autoPlay
                loop={videoSettings.loop}
                preload={'metadata'}
              >
                <source src={videoSettings.videosrc} type={`video/${videoSettings.fileType ?? 'mp4'}`} />
              </video>
            )}
          </Box>
          <Container className={css.container}>
            <Box
              paddingRight={{ mobile: 'none', desktop: 'xxl' }}
              paddingTop={{ mobile: 'l', desktop: 'xxxl' }}
              paddingBottom={{ mobile: 'l', desktop: 'xxxl' }}
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              gap={{ mobile: 'l', desktop: 'xxl' }}
            >
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                <Heading as={'h1'} fontFamily={'accent'} color={'malm0'} fontWeight={'normal'} fontSize={'xxl'} margin={'none'} lineHeight={'xs'}>
                  Stories
                </Heading>
                <Box display={{ mobile: 'none', desktop: 'flex' }} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                  <StoriesUnderline />
                </Box>
                <Box display={{ mobile: 'flex', desktop: 'none' }} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
                  <StoriesUnderline width={'48'} height={'9'} />
                </Box>
              </Box>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'m'}>
                <Heading as={'h2'} margin={'none'} textAlign={'center'}>
                  {props.title}
                </Heading>
                <Box display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'xxs'}>
                  <Text fontWeight={'semibold'} margin={'none'}>
                    {formattedDate} {readingTime ? `/ ${readingTime} min read` : ''}
                  </Text>
                  <Text fontWeight={'light'} fontSize={'l'} margin={'none'} textAlign={'center'}>
                    {props.intro}
                  </Text>
                </Box>
                {author && (
                  <Box display={'flex'} flexDirection={'row'} alignItems={'center'} gap={'xxs'}>
                    {author.photo && (
                      <Image
                        src={author.photo}
                        width={49}
                        height={49}
                        alt={'Author image'}
                        style={{ objectFit: 'cover', height: 49, width: 49, borderRadius: '50%' }}
                      />
                    )}
                    <Box display={'flex'} flexDirection={'column'} alignItems={author.photo ? 'flex-start' : 'center'}>
                      <Text fontWeight={'semibold'} fontSize={'s'} margin={'none'}>
                        Written by {author.name}
                      </Text>
                      <Text fontWeight={'light'} fontSize={'s'} margin={'none'}>
                        {author.email}
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {/* Empty column to match layout */}
            <Box />
          </Container>
        </Box>
      )}
    </>
  )
}

export default HeroBlog
