/* eslint-disable @next/next/no-img-element */
import { Box, Button, Text } from 'atomic'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import { Link } from 'components/link/Link'
import { FC } from 'react'
import * as css from './ButtonBox.css'
import { ouTheme } from 'atomic/styles/theme.css'
import { ButtonType } from 'atomic/components/atoms/button/Button'
import { BuilderProps, removeKeys } from '../helpers/BuilderPropCleaner'

type ButtonBoxProps = {
  link: string
  text: string
  buttonText: string
  buttonType: ButtonType
  image: string
  backgroundColor: keyof typeof ouTheme.colors
} & BoxProps &
  BuilderProps

const ButtonBox: FC<ButtonBoxProps> = ({
  link,
  text,
  buttonText,
  buttonType,
  image,
  backgroundColor,
  paddingLeft = 's',
  paddingRight = 's',
  ...props
}) => {
  // Remove Builder.io specific props
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })

  return (
    <Box className={css.outerContainer} paddingRight={paddingRight} paddingLeft={paddingLeft} {...cleanProps}>
      <Box background={backgroundColor} className={css.ctaContainer}>
        <Link href={link} className={css.ctaContent}>
          <img src={image} alt={text} className={css.ctaImage} />
          <Box padding={'m'}>
            <Text fontWeight={'semibold'}>{text}</Text>
            <Button variant={buttonType} marginTop={'m'}>
              {buttonText}
            </Button>
          </Box>
        </Link>
      </Box>
    </Box>
  )
}

export default ButtonBox
