import { Box, Button, Container, Heading, Text } from 'atomic'
import { FC } from 'react'
import { container, coverImage } from './CoversSection.css'
import { BoxProps } from 'atomic/components/atoms/box/Box'
import Image from 'next/image'
import { ouTheme } from 'atomic/styles/theme.css'
import ExternalLink from 'components/externallink/ExternalLink'
import useAppLinks from 'hooks/useAppLinks'
import { useCmsOrTranslation } from 'hooks/useSafeTranslation'
import { useRouter } from 'next/router'
import { BuilderProps, removeKeys } from 'components/builder_components/helpers/BuilderPropCleaner'

type CoverSectionProps = {
  title?: string
  hardcoverUsp1?: string
  hardcoverUsp2?: string
  hardcoverUsp3?: string
  softcoverUsp1?: string
  softcoverUsp2?: string
  softcoverUsp3?: string
  buttonText?: string
  customCoverImage?: boolean
  hardCoverImage?: string
  softCoverImage?: string
} & BoxProps &
  BuilderProps

const CoversSection: FC<CoverSectionProps> = ({
  title,
  hardcoverUsp1,
  hardcoverUsp2,
  hardcoverUsp3,
  softcoverUsp1,
  softcoverUsp2,
  softcoverUsp3,
  buttonText,
  customCoverImage,
  hardCoverImage,
  softCoverImage,
  ...props
}) => {
  const router = useRouter()
  const { cmsOrLokalise } = useCmsOrTranslation()
  const { activeOsUrl } = useAppLinks()

  const startCreateButtonText = cmsOrLokalise(buttonText, 'productpage_photobooks_create_button')
  const cleanProps = removeKeys(props, { builderBlock: true, builderState: true })
  return (
    <Container {...cleanProps} className={container}>
      <Box display="flex" flexDirection={{ mobile: 'column', tablet: 'row' }} gap={{ mobile: 'l', tablet: 'xs' }} fontSize="s">
        <Box flexGrow={1} style={{ width: '100%' }}>
          <Box background="lav1" className={coverImage} position="relative" color="malm4" fontWeight="semibold" marginBottom="xs" borderRadius="m">
            <Image
              sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 33vw`}
              fill
              style={{ objectFit: 'cover' }}
              src={customCoverImage ? hardCoverImage : '/images/photobooks/cover-types-hardcover.jpg'}
              alt="hardcover"
            />
            <Text padding="m" position="absolute">
              Hardcover
            </Text>
          </Box>
          <ul>
            <li>{cmsOrLokalise(hardcoverUsp1, 'productpage_photobook_covers_hardcover_sub1')}</li>
            <li>{cmsOrLokalise(hardcoverUsp2, 'productpage_photobook_covers_hardcover_sub2')}</li>
            <li>{cmsOrLokalise(hardcoverUsp3, 'productpage_photobook_covers_hardcover_sub3')}</li>
          </ul>
        </Box>
        <Box flexGrow={1} style={{ width: '100%' }}>
          <Box background="lav1" className={coverImage} position="relative" color="malm4" fontWeight="semibold" marginBottom="xs" borderRadius="m">
            <Image
              sizes={`(max-width: ${ouTheme.breakpoints.tablet}) 100vw, 33vw`}
              fill
              style={{ objectFit: 'cover' }}
              src={customCoverImage ? softCoverImage : '/images/photobooks/cover-types-softcover.jpg'}
              alt="softcover"
            />
            <Text padding="m" position="absolute">
              Softcover
            </Text>
          </Box>
          <ul>
            <li>{cmsOrLokalise(softcoverUsp1, 'productpage_photobook_covers_softcover_sub1')}</li>
            <li>{cmsOrLokalise(softcoverUsp2, 'productpage_photobook_covers_softcover_sub2')}</li>
            <li>{cmsOrLokalise(softcoverUsp3, 'productpage_photobook_covers_softcover_sub3')}</li>
          </ul>
        </Box>
      </Box>
      <Box>
        <Heading as="h2" fontSize="l" marginBottom="xl">
          {cmsOrLokalise(title, 'productpage_photobook_covers_header')}
        </Heading>
        <Box display={{ mobile: 'none', tablet: 'block' }}>
          <ExternalLink href={`https://app.onceupon.photo/authentication/signinAnonymously/?productType=photobook&lang=${router.locale}`}>
            <Button variant="secondary" marginBottom="xl">
              {startCreateButtonText}
            </Button>
          </ExternalLink>
        </Box>
        <Box display={{ mobile: 'block', tablet: 'none' }}>
          <ExternalLink href={activeOsUrl}>
            <Button variant="secondary" marginBottom="xl">
              {startCreateButtonText}
            </Button>
          </ExternalLink>
        </Box>
      </Box>
    </Container>
  )
}

export default CoversSection
