import React, { FC } from 'react'

import Box, { BoxProps } from '../box/Box'
import { container } from './Container.css'

const Container: FC<BoxProps> = ({ children, className, marginX = 'auto', paddingX = 's-l', ...props }) => {
  return (
    <Box className={`${container} ${className}`} paddingX={paddingX} marginX={marginX} {...props}>
      {children}
    </Box>
  )
}

export default Container
