import { Box, Heading, Text } from 'atomic'
import { ThemeColors } from 'atomic/styles/theme.css'
import { FC, ReactNode } from 'react'
import { addOn, imageContainer, transparentWhiteBackground } from './PriceCard.css'

const PriceCard: FC<{
  name: string
  image: ReactNode
  price: ReactNode
  color: keyof ThemeColors
  addOns: { price: string; label: string }[]
  size?: string
}> = ({ name, color, price, addOns, image, size }) => {
  return (
    <Box>
      <Box className={imageContainer}>
        {image}
        {size && (
          <Text
            className={transparentWhiteBackground}
            padding="xs"
            paddingTop="xxs"
            paddingBottom="xxs"
            marginTop="m"
            marginLeft="m"
            position="absolute"
            fontSize="xs"
            fontWeight="normal"
            borderRadius="m"
          >
            {size}
          </Text>
        )}
      </Box>
      <Box background={color} paddingX="m" paddingY="l" borderRadius="m">
        <Heading as="h3" fontSize="s" marginBottom="xs">
          {name}
        </Heading>
        <Box marginBottom="m">{price}</Box>
        {addOns?.map(({ label, price }) => (
          <Box className={addOn} key={label} fontSize="s" paddingY="xxs">
            {label}{' '}
            <Text fontWeight="bold" as="p" marginBottom="none">
              + {price}
            </Text>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default PriceCard
