import { Box, Heading, Text } from 'atomic'
import { FC, ReactNode } from 'react'
import { illustrationContainer } from './InfoCard.css'
import { ThemeColors } from 'atomic/styles/theme.css'

type InfoCardProps = {
  color: keyof ThemeColors
  title: string
  text: string
  illustration: ReactNode
}

const InfoCard: FC<InfoCardProps> = ({ color, title, text, illustration }) => (
  <Box display="flex" flexDirection="column" alignItems="flex-start" background={color ?? 'lav4'} padding="m" borderRadius="m">
    <figure className={illustrationContainer}>{illustration}</figure>
    <Heading fontSize="base" as="h3" marginBottom="xxs">
      {title}
    </Heading>
    <Text fontSize="s" as="p">
      {text}
    </Text>
  </Box>
)

export default InfoCard
