import { FC } from 'react'

const PinterestIcon: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill="currentColor" fillRule="nonzero">
        <path d="M10.012 0C4.481 0 0 4.477 0 10.004c0 4.24 2.643 7.856 6.36 9.315-.09-.795-.164-2.009.033-2.87.18-.779 1.174-4.969 1.174-4.969s-.296-.598-.296-1.484c0-1.394.804-2.427 1.814-2.427.853 0 1.264.64 1.264 1.41 0 .861-.55 2.14-.83 3.33-.237.992.501 1.803 1.486 1.803 1.781 0 3.143-1.877 3.143-4.584 0-2.394-1.723-4.067-4.185-4.067-2.848 0-4.522 2.132-4.522 4.338 0 .861.328 1.78.747 2.28.082.098.09.188.066.287-.074.32-.247.992-.28 1.131-.04.18-.147.222-.336.132-1.247-.583-2.035-2.411-2.035-3.88 0-3.156 2.298-6.05 6.614-6.05 3.472 0 6.172 2.476 6.172 5.78 0 3.444-2.175 6.224-5.195 6.224-1.018 0-1.97-.525-2.298-1.148 0 0-.5 1.91-.624 2.378-.23.87-.837 1.96-1.247 2.624.936.287 1.929.443 2.963.443C15.519 20 20 15.523 20 9.996 20.025 4.477 15.544 0 10.012 0z"></path>
      </g>
    </g>
  </svg>
)

export default PinterestIcon
